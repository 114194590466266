import React from 'react'

import './App.css'

class App extends React.Component {
	render() {
		return (
			<div className="splash flex-container">
			  <div className="punchline">Coming soon...</div>
			  <div>
			  	<img src="https://ik.imagekit.io/CO42/assets/img/eye_UPL8ctREu.png?ik-sdk-version=javascript-1.4.3&updatedAt=1645189087949" alt="eye" />
			  </div>
			</div>
		)
	}
}

export default App